import { forwardRef } from 'react'
import styled from 'styled-components'

const Card = styled(
  forwardRef(
    (
      { cName, className, bright, component: Component = 'div', ...props },
      ref
    ) => <Component className={`${cName} ${className}`} {...props} ref={ref} />
  )
)`
  border-radius: 10px;
  background: ${({ bright }) => (bright ? '#ffffff' : '#2c2c2c')};
  color: #939393;
  padding: 14px;
  margin: 12px 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;
`
export default forwardRef(({ className, ...props }, ref) => {
  return <Card cName={className} {...props} ref={ref} />
})
