import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Card from 'components/Card'

const ChooserWrapper = styled(Card)`
  display: inline-flex;
  padding: 0px;

  overflow: hidden;
`

const Container = styled.div``

const Option = styled.span`
  text-transform: capitalize;
  margin: 5px;
  padding: 8px;
  font-size: 13px;
  flex: 1;
  z-index: 1;
  text-align: center;
  color: ${props =>
    props.active ? (props.color === 'primary' ? 'white' : 'black') : 'inherit'};
  ${props =>
    props.highlight
      ? `border: 1px solid ${
          props.color === 'primary' ? '#842008' : 'white'
        }; padding: 7px;`
      : ''};
  transition: color 300ms ease-in-out;
  font-weight: bold;
  border-radius: 8px;
`

const Indicator = styled.div`
  position: absolute;
  top: 5px;
  left: calc(${({ options, active }) => `5px + ${(100 * active) / options}%`});
  width: calc(${({ options }) => 100 / options}% - 10px);
  background: ${({ color }) => (color === 'primary' ? '#842008' : 'white')};
  bottom: 5px;
  transition: left 300ms ease-in-out;
  border-radius: 8px;
`

const messages = {
  'customize.system.imperial': 'Imperial (USA)'
}

function FormattedMessage({ id, defaultMessage }) {
  return messages[id] || defaultMessage || id
}

function Chooser({
  messageId,
  options = ['left', 'right'],
  onChange = () => {},
  value,
  color = 'primary',
  highlight = () => false,
  ...props
}) {
  const [state, setState] = useState(options[0])
  return (
    <Container>
      {props.label && (
        <label {...(props.id ? { htmlFor: id } : {})}>{props.label}</label>
      )}
      <ChooserWrapper {...props}>
        {options.map((val, idx) => (
          <Option
            key={val}
            active={
              typeof value === 'number'
                ? idx === value
                : (value || state) === val
            }
            color={color}
            highlight={highlight(val, idx)}
            onClick={() => onChange(val, idx)}
          >
            <FormattedMessage
              defaultMessage={val.toLowerCase()}
              id={`${messageId}.${val}`}
            />
          </Option>
        ))}

        <Indicator
          active={
            typeof value === 'number' ? value : options.indexOf(value || state)
          }
          color={color}
          options={options.length}
        />
      </ChooserWrapper>
    </Container>
  )
}

export default Chooser
